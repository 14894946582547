import {
  getFirestore,
  serverTimestamp,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  collection,
  getDocs,
  setDoc,
  where,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import { Value } from "../types/values";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebaseApp";

const db = getFirestore();
const pjson = require("../../package.json");

export const firestoreMethods = {
  getUserType: async function (userId) {
    try {
      const res = "guest";
      const docRef = query(collection(db, "users"), where("id", "==", userId));
      const docSnap = await getDocs(docRef);

      if (docSnap.docs.length > 0) {
        if (docSnap.docs[0].data().isAdmin) {
          return "admin";
        } else {
          return "user";
        }
      }

      return res;
    } catch (e) {
      console.log("Error getting  document:", e);
    }
  },
  storeValue: async function (value) {
    try {
      console.log("storing value: ", value);
      let userSnapshot = null;
      if (value.id === "new_id") {
        const id = (await addDoc(collection(db, "values"), value.toFirestore())).id;
        value.id = id;
        return value;
      } else {
        const docRef = doc(db, "values", value.id);
        userSnapshot = await getDoc(docRef);
        // Update the document
        userSnapshot && (await setDoc(docRef, value.toFirestore()));
        return value;
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },
  deleteValue: async function (valueId) {
    try {
      // const docRef = doc(db, "values", valueId);
      // await updateDoc(docRef, {
      //   deletedAt: serverTimestamp(),
      // });
      // console.log("Document deleted with ID: " + docRef.id);
      // return true;
      await deleteDoc(doc(db, "values", valueId));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  },
  loadValues: async function () {
    try {
      const querySnapshot = await getDocs(collection(db, "values"));
      const values = new Map();
      querySnapshot.forEach((doc) => {
        values.set(doc.id, Value.fromFirestore(doc));
      });
      return values;
    } catch (e) {
      console.log("Error getting  document:", e);
    }
  },
  createMarketingOptin: async function (email) {
    try {
      const docRef = await addDoc(collection(db, "marketingOptins"), {
        createdAt: serverTimestamp(),
        email,
      });

      console.log("Document written with ID: " + docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  createResultsFromValues: async function (values, surveyResults, userId) {
    try {
      const newValues = values.map((value) => {
        return {
          adjusted: value.adjusted,
          focused: value.focused,
          selected: value.selected,
          name: value.name,
          valueId: value.id || "",
        };
      });

      const results = {
        version: pjson.version,
        createdAt: serverTimestamp(),
        uid: userId || null,
        values: newValues,
      };
      if (surveyResults) {
        results.survey = surveyResults;
      } else {
        console.log("no survey results");
      }

      const docRef = await addDoc(collection(db, "results"), results);
      console.log("Document written with ID: " + docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  associateResultsIdToUser: async function (id, user) {
    try {
      const docRef = doc(db, "results", id); // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, {
        uid: user?.uid || null,
      });
      console.log("Document updated with ID: " + docRef.id);
      return true;
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  },

  getResultForId: async function (id) {
    // Get a document, forcing the SDK to fetch from the offline cache.
    try {
      const docRef = doc(db, "results", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return null;
      }
    } catch (e) {
      console.log("Error getting  document:", e);
      return null;
    }
  },

  getResultsForUser: async function (user) {
    if (!user?.uid) return [];

    try {
      const querySnapshot = await getDocs(
        query(collection(db, "results"), where("uid", "==", user.uid), orderBy("createdAt", "desc"))
      );
      return querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
    } catch (e) {
      console.log("Error getting  document:", e);
    }
  },

  isUserRegistered: async function (email) {
    try {
      const docRef = query(collection(db, "users"), where("email", "==", email.toLowerCase()));
      const docSnap = await getDocs(docRef);
      console.log("users found: ", docSnap.docs.length);
      return docSnap.docs.length > 0;
    } catch (e) {
      console.log("Error getting  document:", e);
      return false;
    }
  },

  loadUsers: async function () {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const users = new Map();
      querySnapshot.forEach((doc) => {
        users.set(doc.id, doc.data());
      });
      return users;
    } catch (e) {
      console.log("Error getting  document:", e);
    }
  },

  loadUser: async function (userId) {
    try {
      const docRef = query(collection(db, "users"), where("id", "==", userId));
      const docSnap = await getDocs(docRef);

      if (docSnap.docs.length > 0) {
        const docData = docSnap.docs[0].data();
        return { id: docSnap.docs[0].id, user: docData };
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (e) {
      console.log("Error getting  document:", e);
      return null;
    }
  },

  updateUser: async function (userId, user) {
    try {
      const docRef = doc(db, "users", userId);
      await updateDoc(docRef, user);
      console.log("Document updated with ID: " + docRef.id);
      return userId;
    } catch (e) {
      console.error("Error updating document: ", e);
      return "";
    }
  },

  createUser: async function (user) {
    try {
      console.log("creating user...");
      const fncCreateUser = httpsCallable(functions, "api/users/createUser");
      const result = await fncCreateUser({
        user,
      });
      console.log("create user result result: ", result.data);

      if (result.data.status === "OK") {
        const userId = result.data.id;
        user.id = userId;
      } else {
        return null;
      }

      const docRef = await addDoc(collection(db, "users"), user);
      console.log("Document written with ID: " + docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
      return null;
    }
  },

  registerUser: async function (user) {
    try {
      console.log("registering user...");
      const fncRegisterUser = httpsCallable(functions, "api/registerUser");
      const result = await fncRegisterUser({
        user,
      });
      console.log("register user result result: ", result.data);

      if (result.data.status === "OK") {
        const userId = result.data.id;
        return userId;
      } else {
        console.log("registering user failed...");
        return null;
      }
    } catch (e) {
      console.error("Error adding document: ", e);
      return null;
    }
  },

  deleteUser: async function (userId) {
    if (!userId) return "no-user";

    const fncDeleteUser = httpsCallable(functions, "api/users/deleteUser");

    try {
      const response = await fncDeleteUser({ userId });
      console.log("delete user response: ", response);
      if (response.data.status === "OK") {
        return "success";
      } else return "error";
    } catch (err) {
      return "error";
    }
  },

  loadSurveys: async function () {
    try {
      const querySnapshot = await getDocs(collection(db, "surveys"));
      const surveys = new Map();
      querySnapshot.forEach((doc) => {
        surveys.set(doc.id, doc.data());
      });
      return surveys;
    } catch (e) {
      console.log("Error getting  document:", e);
      return new Map();
    }
  },

  loadSurvey: async function (surveyId) {
    try {
      console.log("loading survey: ", surveyId);
      const docRef = doc(db, "surveys", surveyId);
      const docSnap = await getDoc(docRef);

      console.log("docData: ", docSnap);
      if (docSnap.exists()) {
        const survey = docSnap.data();
        console.log("Document data:", survey);
        return survey;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return null;
      }
    } catch (e) {
      console.log("Error getting  document:", e);
      return null;
    }
  },

  storeSession: async function (session_id, cart) {
    try {
      // Create a document reference with the specified session_id
      const docRef = doc(collection(db, "orders"), session_id);

      // Set the document data with the session_id
      await setDoc(docRef, {
        session_id,
        cart,
        createdAt: serverTimestamp(),
      });

      console.log("Document written with ID: " + docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  },

  loadSession: async function (sessionId) {
    try {
      const docRef = doc(db, "orders", sessionId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (e) {
      console.log("Error getting  document:", e);
      return null;
    }
  },

  deleteSession: async function (sessionId) {
    try {
      await deleteDoc(doc(db, "orders", sessionId));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  },
};
