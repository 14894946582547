import React, { useContext, useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/firebaseApp";
import { firebaseAuth } from "../provider/AuthProvider";
import Wrapper from "../components/Wrapper";
import { Box, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { PageTitle, StyledButtonInverted } from "../components/Customs";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import { firestoreMethods } from "../firebase/firestoreMethods";
import { LangContext } from "../provider/LanguageProvider";
import env from "../env-config";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const [selectedTests, setSelectedTests] = useState(new Map());
  const [totalAmount, setTotalAmount] = useState(0);
  const [currency, setCurrency] = useState("EUR");
  const [products, setProducts] = useState([]);
  const [allowNext, setAllowNext] = useState(false);

  const { language } = useContext(LangContext);
  const { user } = useContext(firebaseAuth);

  const navigate = useNavigate();

  const fields = useContentful("orders");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const fncProducts = httpsCallable(functions, "api/getProducts");
        const response = await fncProducts({ testMode: env.dev.payment_test_mode });
        if (response.data) {
          const data = response.data;
          console.log("product price", data);
          setProducts(data);
          if (data.length > 0) {
            const prod = data[0];
            const defaultPrice = prod.prices.find((price) => price.id === prod.defaultPrice);
            if (defaultPrice) {
              setCurrency(defaultPrice.curency.toUpperCase());
            }
          }
        } else {
          console.log("No products found");
          setProducts([]);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (user) {
      setAllowNext(false);
      try {
        fetchProducts();
      } finally {
        setAllowNext(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;
  }, [user]);

  const getPrice = (price_id) => {
    if (!price_id) return { unit_amount: 0 };
    const product = products.find((product) =>
      product.prices.some((price) => price.id === price_id)
    );
    const price = product.prices.find((price) => price.id === price_id);
    return price;
  };

  const handleTestSelection = (e) => {
    console.log(e);
    const { name, value } = e.target;

    const updatedTests = new Map(selectedTests);
    if (value === 0) {
      updatedTests.delete(name);
    } else {
      updatedTests.set(name, { price: name, quantity: parseInt(value) });
    }

    setSelectedTests(updatedTests);
    // setSelectedTests();
    const total = updatedTests
      .entries()
      .reduce(
        (acc, [price_id, { _, quantity }]) =>
          acc + (quantity * getPrice(price_id).unit_amount) / 100,
        0
      );
    setTotalAmount(total);
  };

  const clickNext = async () => {
    setAllowNext(false);
    const cart = [...selectedTests.values()];
    if (cart && cart.length > 0) {
      const data = {
        items: cart,
        returnUrl: window.location.origin + "/completion",
      };

      if (user && user.appUser) {
        data.customerEmail = user.appUser.email;
        data.customerFullName = user.appUser.name + " " + user.appUser.surname;
      }

      if (language) {
        data.locale = language;
      }

      data.testMode = env.dev.payment_test_mode;

      console.log("creating session...");
      const createCheckoutSession = httpsCallable(functions, "api/create-checkout-session");
      const result = await createCheckoutSession(data);
      if (result && result.data) {
        const clS = result.data.clientSecret;
        const session_id = result.data.session.id;
        firestoreMethods.storeSession(session_id, cart);
        console.log("session created: ", result.data);
        navigate("/checkout/" + clS);
      }
    }
    setAllowNext(true);
  };

  if (!fields || !products || products.length === 0)
    return <LoadingWrapper title="Loading products..." />;

  return (
    <Wrapper>
      <PageTitle>{fields.title}</PageTitle>
      <Grid container spacing={2} marginTop={3}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {products.map((product) => (
              <Box key={product.id + "_info_box"} pl={3}>
                <Grid item key={product.id + "_info"} xs={12}>
                  <Typography variant="h5">{product.name}</Typography>
                  <Typography variant="body1" marginBottom={3}>
                    {product.description}
                  </Typography>
                </Grid>
                {product.prices
                  .filter((price) => price.active)
                  .map((price, pindex) => (
                    <Box key={price.id + "_info_box"} display="flex" marginBottom={2}>
                      <Grid item xs={4}>
                        <TextField
                          label={fields.labelQuantityShort || "Qty:"}
                          name={price.id}
                          key={price.id + "_quantity"}
                          select
                          fullWidth
                          size="small"
                          id={price.id}
                          onChange={handleTestSelection}
                          value={selectedTests.get(price.id)?.quantity || 0}
                        >
                          {[...Array(6)].map((_, index) => {
                            const id = `amount_${price.id}_${index}`;
                            return (
                              <MenuItem id={id} key={id} value={index}>
                                {index}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={8}>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          key={pindex + "_box"}
                          sx={{ height: "100%" }}
                        >
                          <Typography variant="body1" align="right">
                            {(fields.labelPrice || "Price:") + " "}
                            {price.unit_amount / 100} {price.currency.toUpperCase()}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  ))}
              </Box>
            ))}
            <Grid item xs={12} justifyContent="flex-end">
              <Divider />
              <Typography variant="body1" align="right">
                {(fields.labelTotal || "Total:") + " "}
                {totalAmount + " " + currency}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box marginLeft={"auto"} width={"80%"}>
            {/* add name and address information here */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <StyledButtonInverted onClick={clickNext} disabled={!allowNext}>
            {fields.buttonNext || "Weiter"}
          </StyledButtonInverted>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Orders;
